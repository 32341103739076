export const getTheme = () => {
  let theme = "";
  let today = new Date().getHours();
  // If current time is between 7AM to 7PM
  if (today >= 7 && today <= 18) {
    theme = "light";
  } else {
    theme = "dark";
  }
  return theme;
};

export const getTimeImage = (val) => {
  var value = "";

  if (val >= 0 && val <= 30) {
    value = "red";
  }
  if (val >= 31 && val <= 90) {
    value = "orange";
  }
  if (val > 90) {
    value = "green";
  }

  return value;
};

export const getUser = () => {
  return JSON.parse(localStorage.getItem("user_details"));
}

export const convertArrayToArrayObject = (array) => {
  if(array && array.length>0)
  {
    let options = array?.map((item,i)=>({
      label : item,
      value: item
    }))
   return options
  }
  return []
  
}

export const removeDuplicates = (arr) => {
  return arr.filter((item, index) => arr.indexOf(item) === index);
};

export const findDuplicateFromArrObj = (arr,key,value) => {
  let index = arr.findIndex(
    (elem) => elem[key] === value)
  return index === -1 ?  false : true;
};

// remove spaces
export const trimSpaces = (str) => {
  return str.trim();
};

//check perticular value is array of object
export const isArrayOfObject = (arrayOfObject) => {
  return Array.isArray(arrayOfObject) && arrayOfObject.every((item) =>  typeof item === 'object')
}

// export const setWithExpiry = (key, value, ttl) => {
//   const now = new Date();

//   // `item` is an object which contains the original value
//   // as well as the time when it's supposed to expire
//   const item = {
//     value: value,
//     expiry: now.getTime() + ttl,
//   };
//   localStorage.setItem(key, JSON.stringify(item));
// };

// export const getWithExpiry = (key) => {
//   const itemStr = localStorage.getItem(key);
//   // if the item doesn't exist, return null
//   if (!itemStr) {
//     return null;
//   }
//   const item = JSON.parse(itemStr);
//   const now = new Date();
//   // compare the expiry time of the item with the current time
//   if (now.getTime() > item.expiry) {
//     // If the item is expired, delete the item from storage
//     // and return null
//     localStorage.removeItem(key);
//     return null;
//   }
//   return item.value;
// };
