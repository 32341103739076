//rl bms links
export const API_RISK="https://cassa-services-dev.relevancelab.com/cassa-csc"

export const API_RESULTSET_URL_AUTH = "https://apigateway.relevancelab.com/prismmicro-resultset";//for get list of users


export const API_CUBIX_URL ="http://192.168.101.78:8765/cassa";												
export const API_BASE_URL = "https://apigateway.relevancelab.com";						
export const API_RESULTSET_URL = "http://rigel-api-gateway-dev-demo.relevancelab.com";						
export const API_ENTITLEMENT_URL = "https://apigateway.relevancelab.com/entitlement";						
export const API_DASHBOARD_URL = "https://apigateway.relevancelab.com/prismmicro-dashboard";						
export const API_AUTOCOMPLETE_URL = "https://swagger.mareana.com/v1.0";						
						
export const API_SEARCH_URL = "https://apigateway.relevancelab.com/prismmicro-search";						
export const API_LOGIN_URL = "https://cubix-service.relevancelab.com/rigel-mongo-auth";						
					
					
export const API_AUTHENTICATION_URL = "https://cassa-services-dev.relevancelab.com/cassa-auth";						
											
export const API_CASSA="https://cassa-services-dev.relevancelab.com/cassa-csc"						
											
export const API_ALERT="https://cassa-services-dev.relevancelab.com/cassa-alert"						
						
export const APP_AUTH="https://cassa-auth-dev.relevancelab.com";						
export const API_AUTH="https://cassa-auth-dev.relevancelab.com";

export const API_RESULTSET_URL_1 = "https://apigateway.relevancelab.com/prism-resultset";						
						
export const API_CASSA_DEMO="https://cassa-services-dev.relevancelab.com/cassa-csc"
export const API_ALERT_DEMO="https://cassa-services-dev.relevancelab.com/cassa-alert"

// cassa-services-dev.relevancelab.com/cassa-csc
// cassa-services-dev.relevancelab.com/cassa-alert
// cassa-services-dev.relevancelab.com/cassa-auth

	
export const API_UAT_AUTH="https://sso-qa.bms.com/auth/saml2/jsp/idpSSOInit.jsp?metaAlias=/BMS/urn:bms:idp:qa:115:50&spEntityID=https://cassa3-0.web-uat.bms.com/saml/metadata"