import { API_AUTHENTICATION_URL,API_RESULTSET_URL } from '../constants/apiBaseUrl';
import { AjaxService } from "../utils/AjaxService";

export const createUser = (_queryParam) => {
  return AjaxService.post(
    API_AUTHENTICATION_URL + "/api/user/save",
    JSON.stringify(_queryParam)
  ).then(
    (res) => res.data,
    (error) => {
      throw error.response.data;
    }
  );
};

export const updateUser = (_queryParam) => {
  return AjaxService.post(
    API_AUTHENTICATION_URL + "/api/user/update",
    JSON.stringify(_queryParam)
  ).then(
    (res) => res.data,
    (error) => {
      throw error.response.data;
    }
  );
};

export const loadFilter = (_queryParam) => {
  return AjaxService.post(
    API_RESULTSET_URL + "/loadFilter",
    JSON.stringify(_queryParam)
  ).then(
    (res) => res.data,
    (error) => {
      throw error.response.data;
    }
  );
};

//get user data by id
export const getUserById = (_queryParam) => {
  return AjaxService.get(
    API_AUTHENTICATION_URL + "/api/user/"+_queryParam
  ).then(
    (res) => res.data,
    (error) => {
      throw error.response.data;
    }
  );
};

export const newgetUsers = (_queryParam) => {
  return AjaxService.get(
    API_AUTHENTICATION_URL + "/api/user/allUsers"
  ).then(
    (res) => res.data,
    (error) => {
      throw error.response.data;
    }
  );
};

export const enableUser = (_queryParam) => {
  return AjaxService.post(
    API_AUTHENTICATION_URL + "/api/user/enable",
    JSON.stringify(_queryParam)
  ).then(
    (res) => res.data,
    (error) => {
      throw error.response.data;
    }
  );
};


export const getAllAccount = (_queryParam) => {
  return AjaxService.get(
    API_AUTHENTICATION_URL + "/api/group/getAllApps",
    JSON.stringify(_queryParam)
  ).then(
    (res) => res.data,
    (error) => {
      throw error.response.data;
    }
  );
};

export const getAllRoles = (_queryParam) => {
  return AjaxService.get(
    API_AUTHENTICATION_URL + "/api/roles/allRoles",
    JSON.stringify(_queryParam)
  ).then(
    (res) => res.data,
    (error) => {
      throw error.response.data;
    }
  );
};


export const getAllGroups = (_queryParam) => {
  return AjaxService.get(
   `${API_AUTHENTICATION_URL}/api/group/list?accountId=${_queryParam}`,

  ).then(
    (res) => res.data,
    (error) => {
      throw error.response.data;
    }
  );
};

//https://rigel-api-gateway-dev-demo.relevancelab.com/rigel-mongo-auth/api/user/5008
export const removeUserById = (_queryParam) => {
  return AjaxService.delete(
    `${API_AUTHENTICATION_URL}/api/user/${_queryParam.id}`,    
  ).then(
    (res) => res.data,
    (error) => {
      throw error.response.data;
    }
  );
};

export const getGroupsByRoleAndType = (name,type) => {
  return AjaxService.get(
    `${API_AUTHENTICATION_URL}/api/roles/${name}/${type}`,
  ).then(
    (res) => res.data,
    (error) => {
      throw error.response.data;
    }
  );
};

export const getAllGroupsByAccountId = (accountId) => {
  return AjaxService.get(
   `${API_AUTHENTICATION_URL}/api/roles/${accountId}`,

  ).then(
    (res) => res.data,
    (error) => {
      throw error.response.data;
    }
  );
};

export const getUserDetailsByToken = (_queryParam) => {
  return AjaxService.get(
   API_AUTHENTICATION_URL + `/api/user/getUserDetailsByToken?token=${_queryParam.token}`,
  ).then(
    (res) => res.data,
    (error) => {
      throw error.response.data;
    }
  );
};